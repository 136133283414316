import React from 'react'
import createReactClass from 'create-react-class'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import CircularProgress from 'material-ui/CircularProgress'
import Portal from 'react-travel'

import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

const Button = createReactClass({
    getInitialState: function() {

        const { disabled = false } = this.props

        return {
            isProcessing: false,
            disabled: disabled
        }
    },
    setIsProcessing: async function(value, disabled) {
        const { isProcessing } = this.state
        const currentDisabled = this.state.disabled
        if (isProcessing !== value) {
            await this.setState({
                isProcessing: value,
                disabled: (typeof disabled == 'boolean') ? disabled : currentDisabled
            })
        }
    },
    setDisabled: async function(value) {
        const { disabled } = this.state
        if (disabled !== value) {
            await this.setState({
                disabled: value
            })
        }
    },
    render: function() {

        const { isProcessing, disabled } = this.state
        const { ButtonElement, ...rest } = this.props
        const BE = (ButtonElement) ? ButtonElement : FlatButton

        return <BE
            disabled={(isProcessing) ? true : disabled}
            icon={(isProcessing) ? <CircularProgress size={24} thickness={3} style={{ lineHeight: 'normal' }} /> : null}
            {...rest}
        />
    }
})

const Wapplrdialog = createReactClass({
    getInitialState: function() {

        this.refElements = {}

        const {
            action,
            title,
            text,
            content,
            contentprops,
            submittext,
            canceltext,
            submitprops,
            cancelprops,
            leftbuttons,
            onclosebefore,
            disablebodyoverflowhidden,
            dialogopen
        } = this.props

        const regenerateActions = (
            typeof action !== 'undefined' ||
            typeof submitprops !== 'undefined' ||
            typeof cancelprops !== 'undefined' ||
            typeof submittext !== 'undefined' ||
            typeof canceltext !== 'undefined')

        const rsubmittext = (typeof submittext !== 'undefined') ? submittext : ''
        const rcanceltext = (typeof canceltext !== 'undefined') ? canceltext : ''
        const actions = (regenerateActions) ? this.dialogActions({
            action,
            submittext: rsubmittext,
            canceltext: rcanceltext,
            submitprops,
            cancelprops
        }) : null

        return {
            submittext: rsubmittext,
            canceltext: rcanceltext,
            dialogtitle: title || '',
            leftbuttons: leftbuttons || null,
            dialogtext: (typeof text == 'string') ? text : '',
            dialogcontent: content,
            dialogcontentprops: contentprops,
            dialogactions: actions,
            dialogopen: dialogopen || false,
            submitprops: submitprops || {},
            cancelprops: cancelprops || {},
            onclosebefore: onclosebefore || null,
            action: action || null,
            disablebodyoverflowhidden: disablebodyoverflowhidden || false,
            getButtons: this.props.getButtons
        }
    },
    dialogClose: async function() {
        const { onclosebefore } = this.state
        const t = this
        const next = async function() {
            t.removeClassFromBody()
            await t.setState({
                dialogopen: false
            })
        }
        if (onclosebefore) {
            const prevent = onclosebefore(next)
            if (prevent) return
        }
        await next()
    },
    processingStart: function() {
        const submitButton = this.refElements['submitbutton']
        submitButton.setIsProcessing(true)
    },
    processingEnd: async function(p = {}) {

        const submitButton = this.refElements['submitbutton']
        submitButton.setIsProcessing(false)

        if (p) {
            await this.setState(p)
        }

        const action = (this.state) ? this.state.action : null
        const { canceltext = 'Cancel', submittext = 'Submit', submitprops = {}, cancelprops = {} } = this.state
        const actions = this.dialogActions({ action, submittext, canceltext, submitprops, cancelprops })

        await this.setState({
            dialogactions: actions
        })

    },
    addClassToBody: function() {
        const { style } = this.props
        const { disablebodyoverflowhidden } = this.state
        if (typeof window !== 'undefined' && !disablebodyoverflowhidden && style.bodyClass) {
            if (!this.tempClassName) {
                this.tempClassName = window.document.body.className
            }
            window.document.body.className = window.document.body.className + ' ' + style.bodyClass
        }
    },
    removeClassFromBody: function() {
        if (typeof window !== 'undefined') {
            if (this.tempClassName || this.tempClassName === '') {
                window.document.body.className = this.tempClassName
                this.tempClassName = null
            }
        }
    },
    dialogOpen: async function(p) {

        const props = (p) ? p : this.props
        const {
            action,
            title,
            text,
            content,
            contentprops,
            submittext,
            canceltext,
            submitprops,
            cancelprops,
            leftbuttons,
            onclosebefore,
            disablebodyoverflowhidden,
            getButtons
        } = props

        const regenerateActions = (
            typeof action !== 'undefined' ||
            typeof submitprops !== 'undefined' ||
            typeof cancelprops !== 'undefined' ||
            typeof submittext !== 'undefined' ||
            typeof canceltext !== 'undefined' ||
            !this.state.dialogactions)

        const rsubmittext = (typeof submittext !== 'undefined') ? submittext : this.state.submittext
        const rcanceltext = (typeof canceltext !== 'undefined') ? canceltext : this.state.canceltext
        const actions = (regenerateActions) ? this.dialogActions({
            action,
            submittext: rsubmittext,
            canceltext: rcanceltext,
            submitprops,
            cancelprops
        }) : this.state.dialogactions
        const rdisablebodyoverflowhidden = (typeof disablebodyoverflowhidden !== 'undefined') ? disablebodyoverflowhidden : this.state.disablebodyoverflowhidden

        await this.setState({
            submittext: rsubmittext,
            canceltext: rcanceltext,
            dialogtitle: (typeof title !== 'undefined') ? title : this.state.dialogtitle,
            dialogtext: (typeof text == 'string') ? text : this.state.dialogtext,
            dialogcontent: (typeof content !== 'undefined') ? content : this.state.dialogcontent,
            dialogcontentprops: (typeof contentprops !== 'undefined') ? contentprops : this.state.dialogcontentprops,
            dialogactions: actions,
            submitprops: (regenerateActions) ? submitprops : this.state.submitprops,
            cancelprops: (regenerateActions) ? cancelprops : this.state.cancelprops,
            leftbuttons: (typeof leftbuttons !== 'undefined') ? leftbuttons : this.state.leftbuttons,
            onclosebefore: (typeof onclosebefore !== 'undefined') ? onclosebefore : this.state.onclosebefore,
            action: (regenerateActions) ? action : this.state.action,
            disablebodyoverflowhidden: rdisablebodyoverflowhidden,
            dialogopen: true,
            getButtons: (typeof getButtons !== 'undefined') ? getButtons : this.state.getButtons
        })

        if (!rdisablebodyoverflowhidden) this.addClassToBody()

        const processingStart = this.processingStart
        const processingEnd = this.processingEnd
        return { processingStart, processingEnd }
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    dialogActions: function({
                                action,
                                submittext = 'Submit',
                                canceltext = 'Cancel',
                                submitprops = {},
                                cancelprops = {}
                            }) {

        const { style } = this.props
        const setRef = this.setRef

        if (canceltext) {
            return [
                <Button
                    key={0}
                    label={canceltext}
                    primary={true}
                    onTouchTap={this.dialogClose}
                    {...cancelprops}
                />,
                <Button
                    className={style.button}
                    key={1}
                    label={submittext}
                    primary={true}
                    keyboardFocused={true}
                    onTouchTap={(action) ? action : this.dialogClose}
                    ref={function(e) {
                        setRef('submitbutton', e)
                    }}
                    {...submitprops}
                />
            ]
        } else if (submittext) {
            return [
                <Button
                    className={style.button}
                    key={1}
                    label={submittext}
                    primary={true}
                    keyboardFocused={true}
                    onTouchTap={(action) ? action : this.dialogClose}
                    ref={function(e) {
                        setRef('submitbutton', e)
                    }}
                    {...submitprops}
                />
            ]
        } else {
            return null
        }
    },
    componentWillUnmount: function() {
        this.removeClassFromBody()
    },
    getButtons: function() {
        const { getButtons } = this.state
        if (getButtons) {
            return getButtons(this)
        }
        return null
    },
    render: function() {

        const { className, bodyClassName, autoScrollBodyContent, modal = false, renderTo, style } = this.props
        const Dc = this.state.dialogcontent
        const dcp = (this.state.dialogcontentprops) ? this.state.dialogcontentprops : {}
        dcp.dialogRefElements = this.refElements

        const fixedClassName = (typeof document !== 'undefined' && renderTo === document.body) ? style.fixedParent : null
        const buttons = this.getButtons()

        return (
            <div>
                {(renderTo) ?
                    <Portal isOpened={true} renderTo={renderTo}>
                        <div
                            className={(className) ? style.renderToRoot + ' ' + fixedClassName + ' ' + className : style.renderToRoot + ' ' + fixedClassName}
                            style={{ display: (this.state.dialogopen) ? 'block' : 'none' }}>
                            <div className={style.renderToLayer}>
                                <div className={style.renderToPaper}>
                                    {(this.state.dialogtitle) ?
                                        <div className={style.renderToTitle}>{this.state.dialogtitle}</div> : null}
                                    <div className={style.renderToPaperContent}>
                                        <div className={style.renderToPaperContentInner}>
                                            {(this.state.dialogtext) ? this.state.dialogtext : null}
                                            {(Dc) ? <Dc {...dcp} /> : null}
                                            {(buttons) ? <div className={style.buttons}>{buttons}</div> : null}
                                        </div>
                                    </div>
                                    <div className={style.renderToPaperFooter}>
                                        {(this.state.leftbuttons) ? <div
                                            className={style.renderToLeftButtons}>{this.state.leftbuttons}</div> : null}
                                        {this.state.dialogactions}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Portal> :
                    <Dialog
                        className={(className) ? style.dialog + ' ' + className : style.dialog}
                        title={this.state.dialogtitle}
                        actions={this.state.dialogactions}
                        modal={modal}
                        open={this.state.dialogopen}
                        onRequestClose={this.dialogClose}
                        autoScrollBodyContent={autoScrollBodyContent}
                        bodyClassName={bodyClassName}
                    >
                        {(this.state.dialogtext) ? this.state.dialogtext : null}
                        {(Dc) ? <Dc {...dcp} /> : null}
                        {(buttons) ? <div className={style.buttons}>{buttons}</div> : null}
                    </Dialog>
                }
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        if (setRef) setRef(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <Wapplrdialog {...this.props} ref={(setRef) ? function(e) {
                setRef('dialog', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
